import { Platform } from "react-native"

import axios from "axios"

import {
  AppManifest5,
  AppPage,
  AppModule,
  AppManifestStatus,
} from "@treefort/api-spec"
import { isPlatformsConditionMet } from "@treefort/lib/conditions"
import {
  AppManifestLocation,
  getAppManifestLocation as originalGetAppManifestLocation,
} from "@treefort/lib/get-app-manifest-location"

import config from "../config"
import { Store } from "./store"

export type AppManifest = AppManifest5

export const APP_MANIFEST_VERSION = "5"

const STORE_KEY_LOCAL = `local.${config.ENV}.${APP_MANIFEST_VERSION}`
const STORE_KEY_QUEUED = `queued.${config.ENV}.${APP_MANIFEST_VERSION}`

// Bump this constant to reset the cache and ensure the manifest will be
// re-fetched accross all clients. Helpful when adding new fields to a manifest
// that new clients require.
const RESET_CACHE = 14

const store = new Store({
  key: "appManifest",
  migrations: [
    {
      name: `reset:${RESET_CACHE}`,
      migrate: (store) => store.clear(),
    },
  ],
})

export const getAppManifestLocation = <T extends AppManifestStatus>(
  status: T,
): AppManifestLocation<T> =>
  originalGetAppManifestLocation({
    version: APP_MANIFEST_VERSION,
    status,
    appId: config.APP_ID,
    tenantId: config.TENANT_ID,
    env: config.ENV,
  }) as AppManifestLocation<T>

/**
 * Fetch the manifest from the server.
 */
export const getRemoteAppManifest = async (): Promise<AppManifest> => {
  const location = getAppManifestLocation(
    config.ENV === "development" ? "draft" : "published",
  )
  const url =
    location.status === "draft"
      ? `${config.API_ENDPOINT}${location.apiPath}`
      : `${config.CDN_ENDPOINT}${location.cdnPath}`
  const { data: manifest } = await axios.get<AppManifest>(url, {
    headers: { "X-Treefort-Tenant": config.TENANT_ID },
  })
  return manifest
}

/**
 * Fetch the manifest from the local store.
 */
export const getLocalManifest = (): Promise<AppManifest | null> =>
  store.get<AppManifest>(STORE_KEY_LOCAL)

/**
 * Save the manifest locally for fast access.
 */

export const setLocalManifest = (manifest: AppManifest): Promise<void> =>
  store.set(STORE_KEY_LOCAL, manifest)

/**
 * Fetch the queued manifest from the local store.
 */
export const getQueuedManifest = (): Promise<AppManifest | null> =>
  store.get<AppManifest>(STORE_KEY_QUEUED)

/**
 * Queue a manifest to try in the future.setQueuedManifest
 */

export const setQueuedManifest = (manifest: AppManifest): Promise<void> =>
  store.set(STORE_KEY_QUEUED, manifest)

/**
 * Extract a page object from a manifest
 */
export const getPage = (
  pageId: number,
  manifest: AppManifest,
): AppPage | undefined => manifest.pages.find((page) => page.id === pageId)

/**
 * Get all the modules that should be rendered for a page
 */
export const getPageModules = (
  page: AppPage,
  manifest: AppManifest,
): AppModule[] =>
  page.moduleIds
    .map((moduleId) =>
      manifest.modules.find((module) => module.id === moduleId),
    )
    .filter((module?: AppModule): module is AppModule =>
      Boolean(
        module && isPlatformsConditionMet(module.conditions, Platform.OS),
      ),
    )
