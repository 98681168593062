import React, { useEffect, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { Platform } from "react-native"

import queryString from "query-string"
import styled from "styled-components/native"

import {
  StripePromoCodeResponse,
  StripeProrationAvailableResponse,
  WebPaymentPromoCodeResponse,
} from "@treefort/api-spec"
import { useAuth } from "@treefort/lib/auth-provider"
import { formatMoney } from "@treefort/lib/money"
import { simpleHash } from "@treefort/lib/simple-hash"
import strictEncodeUriComponent from "@treefort/lib/strict-encode-uri-component"

import AsyncView, { AsyncViewProps } from "../../../components/async-view"
import {
  BillingIntervalToggle,
  BillingInterval,
} from "../../../components/billing-interval-toggle"
import Column from "../../../components/column"
import ImageContained from "../../../components/image-contained"
import {
  PromoCodeInput,
  InputState,
} from "../../../components/promo-code-input"
import { RestorePurchasesLink } from "../../../components/restore-purchases-link"
import {
  SignUpOptionCard,
  SIGN_UP_CARD_MAX_WIDTH_PX,
} from "../../../components/sign-up-option-card"
import Spacer from "../../../components/spacer"
import StripeProrationPreviewModal from "../../../components/stripe-proration-preview-modal"
import { useTenantLogo } from "../../../components/tenant-logo"
import Text from "../../../components/text"
import { useTokens } from "../../../components/tokens-provider"
import { useUserSubscriptions } from "../../../hooks/subscriptions"
import useAppManifest from "../../../hooks/use-app-manifest"
import { useAsyncViewPropsForQueries } from "../../../hooks/use-async-view-props-for-queries"
import { useMenu } from "../../../hooks/use-menu"
import { useRoute } from "../../../hooks/use-route"
import useSafeAreaInsetAppHeader from "../../../hooks/use-safe-area-inset-app-header"
import { useSignUpOptionsData } from "../../../hooks/use-sign-up-options-data"
import api from "../../../lib/api"
import authenticator from "../../../lib/authenticator"
import { checkoutSessionManager, Event } from "../../../lib/checkout"
import {
  checkGroupMembershipCode,
  getInvalidGroupMembershipCodeMessage,
} from "../../../lib/group-membership"
import { logError } from "../../../lib/logging"
import { getPromoCodeDiscountMessage } from "../../../lib/promo-codes"
import {
  SignUpOption,
  getBillingIntervalsToShow,
  getSignUpOptions,
} from "../../../lib/sign-up-options"
import { formatPlanIntervalShort } from "../../../lib/subscription-plans"
import {
  getCurrentSubscription,
  subscriptionWillRenew,
} from "../../../lib/subscriptions"
import { unsetQueryParam } from "../../../lib/url"
import { i18nKey } from "../../../types/i18next"
import MenuLayout from "../../layouts/menu"
import PageLayout from "../../layouts/page"
import { SplashScreen } from "../splash"

const CARD_ROW_GAP_PX = 24
const CARD_COLUMN_GAP_PX = 36
const CALL_TO_SUBSCRIBE_TEXT_MAX_WIDTH_PX = 480
const TENANT_LOGO_MAX_HEIGHT_PX = 64
const TENANT_LOGO_MAX_WIDTH_PX = 94
const SIGN_UP_CODE_INPUT_DEBOUNCE_MS = 2000
const PASSTHROUGH_ROUTE_PARAM = "passthrough"

const includeCodeInput = Platform.OS === "web"
const includeStripeProrationPreviewModal = Platform.OS === "web"

/**
 * Get the number of cards per row that will a) fit on the screen and b) allow
 * the cards to wrap in a balanced way such that all rows will either have the
 * same number of cards or the last row will have one less card than the other
 * rows.
 */
function getCardsPerRow({
  displayWidth,
  cardCount,
}: {
  displayWidth: number
  cardCount: number
}) {
  const maxCardsPerRow = Math.floor(
    (displayWidth - CARD_ROW_GAP_PX) /
      (SIGN_UP_CARD_MAX_WIDTH_PX + CARD_ROW_GAP_PX),
  )
  for (let cardsPerRow = maxCardsPerRow; cardsPerRow > 0; cardsPerRow--) {
    const leftoverCards = cardCount > cardsPerRow ? cardCount % cardsPerRow : 0
    if (leftoverCards === 0 || cardsPerRow - leftoverCards === 1) {
      return cardsPerRow
    }
  }
  return maxCardsPerRow
}

function getCardKey(option: SignUpOption) {
  return `${option.title}${option.terms ? simpleHash(option.terms) : ""}`
}

const CardRow = styled.View`
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  padding: ${({ theme }) => `0 ${theme.spacing.small}px`};
  width: 100%;
  gap: ${CARD_ROW_GAP_PX}px;
`

const CardColumn = styled.View`
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: ${CARD_COLUMN_GAP_PX}px;
`

const PaddingContainer = styled(Column)<{ layout: "wide" | "narrow" }>`
  padding-horizontal: ${({ theme, layout }) =>
    theme.checkoutScreen.container.paddingHorizontal[layout]}px;
  padding-bottom: ${({ theme }) =>
    theme.appHeader.mode === "desktop" ? theme.spacing.jumbo + "px" : 0};
`

function Layout({
  layout,
  children,
  ...asyncViewProps
}: {
  layout: "wide" | "narrow"
  children: React.ReactNode
} & AsyncViewProps) {
  const { tokens, displayWidth } = useTokens()
  const appHeaderSafeAreaInset = useSafeAreaInsetAppHeader()
  const desktop =
    layout === "wide" &&
    displayWidth >= tokens.breakpoints.desktop &&
    Platform.OS === "web"
  const backgroundColor = tokens.colors.background.tertiary
  return desktop ? (
    <PageLayout
      backgroundColor={backgroundColor}
      headerBackgroundColor={backgroundColor}
    >
      <AsyncView backgroundColor={backgroundColor} {...asyncViewProps}>
        <Spacer size={appHeaderSafeAreaInset} />
        {children}
        <Spacer size="large" />
      </AsyncView>
    </PageLayout>
  ) : (
    <MenuLayout paddingHorizontal={0} backgroundColor={backgroundColor}>
      <AsyncView backgroundColor={backgroundColor} {...asyncViewProps}>
        {children}
      </AsyncView>
    </MenuLayout>
  )
}

function CallToSubscribe({
  layout,
  onReady,
}: {
  layout: "wide" | "narrow"
  onReady: (ready: boolean) => void
}) {
  const { tokens, displayMode } = useTokens()
  const tenantLogo = useTenantLogo()
  const manifest = useAppManifest()
  const { t } = useTranslation()

  useEffect(() => {
    if (tokens.appHeader.mode === "desktop") {
      onReady(true)
    }
  }, [onReady, tokens.appHeader.mode])

  return (
    <Column
      alignItems="center"
      maxWidth={CALL_TO_SUBSCRIBE_TEXT_MAX_WIDTH_PX}
      paddingBottom="none"
    >
      {tokens.appHeader.mode === "mobile" ? (
        <>
          <ImageContained
            containerSize={{
              width: TENANT_LOGO_MAX_WIDTH_PX,
              height: TENANT_LOGO_MAX_HEIGHT_PX,
            }}
            uri={tenantLogo[displayMode]}
            onReady={onReady}
          />
          <Spacer size={layout === "wide" ? "large" : "medium"} />
        </>
      ) : null}
      <Text textStyle="headingMedium" alignment="center">
        {t(manifest.strings.callToSubscribeMessage as i18nKey)}
      </Text>
    </Column>
  )
}

export default function CheckoutScreen(): JSX.Element {
  const { t, i18n } = useTranslation()
  const route = useRoute()
  const auth = useAuth()
  const userSubscriptions = useUserSubscriptions()
  const subscription = getCurrentSubscription(userSubscriptions?.data)
  const [callToSubscribe, setCallToSubscribeReady] = useState(false)
  const { displayWidth } = useTokens()
  const [membershipCode, setMembershipCode] = useState<string>()
  const [codeInputValue, setCodeInputValue] = useState(
    route.params.promoCode || route.params.membershipCode || "",
  )
  const [codeInputState, setCodeInputState] = useState<InputState>({
    type: "idle",
  })
  const [promoCodeEligiblePlanIds, setPromoCodeEligiblePlanIds] = useState<
    number[] | undefined
  >(undefined)
  const [groupMembershipPlanId, setGroupMembershipPlanId] = useState<number>()
  const [loadingRouteSignUpCode, setLoadingRouteSignUpCode] = useState(
    Boolean(route.params.promoCode || route.params.membershipCode),
  )
  const [userSelectedBillingInterval, setUserSelectedBillingInterval] =
    useState<BillingInterval>()

  // If the user is switching Stripe subscription plans then we show them a modal
  // previewing the changes
  const [stripePreviewModalState, setStripePreviewModalState] = useState<
    | { open: false }
    | {
        open: true
        action: () => Promise<void>
        preview: StripeProrationAvailableResponse
        title: string
      }
  >({ open: false })

  // If the screen is only wide enough for a single card then we're in the
  // narrow layout, otherwise we're in the wide layout
  const layout =
    displayWidth >=
    (SIGN_UP_CARD_MAX_WIDTH_PX + CARD_ROW_GAP_PX) * 2 + CARD_ROW_GAP_PX
      ? "wide"
      : "narrow"

  // Close the menu when a checkout session is complete
  const menu = useMenu()
  useEffect(() => {
    return checkoutSessionManager.on(
      Event.CheckoutSessionEnded,
      ({ complete }) => {
        if (complete) {
          menu.close()
        }
      },
    )
  }, [menu])

  /**
   * SIGN UP OPTIONS
   *
   * Determine which cards to show on the page based on the platform, the user,
   * the offeringIds param, and a host of other factors.
   */

  // If the user is upgrading or subscribing to gain access to a particular
  // peice of content or feature then the offeringIds query param is set
  const offeringIds = useMemo(() => {
    return Array.isArray(route.params.offeringIds)
      ? route.params.offeringIds.flatMap((id) =>
          !isNaN(parseInt(id)) ? parseInt(id) : [],
        )
      : route.params.offeringIds && !isNaN(parseInt(route.params.offeringIds))
        ? [parseInt(route.params.offeringIds)]
        : undefined
  }, [route.params.offeringIds])

  const signUpOptionsData = useSignUpOptionsData({
    offeringIds: offeringIds?.length ? offeringIds : undefined,
    groupMembershipPlanId,
  })

  // If the user is subscribing to gain access to a particular peice of content
  // then the checkoutContentId query param should be set
  const contentId =
    route.params.checkoutContentId &&
    !isNaN(parseInt(route.params.checkoutContentId))
      ? parseInt(route.params.checkoutContentId)
      : undefined

  const recommId = route.params.recommId
  const highlightSubscriptionPlanId =
    route.params.plan && !isNaN(parseInt(route.params.plan))
      ? parseInt(route.params.plan)
      : undefined
  const currentSubscriptionPlan =
    subscription && !subscription.deactivatedAt
      ? signUpOptionsData.data?.availableSubscriptionPlans.find(
          (plan) => plan.id === subscription.subscriptionPlanId,
        )
      : undefined
  const billingInterval = groupMembershipPlanId
    ? "all"
    : userSelectedBillingInterval
      ? userSelectedBillingInterval
      : signUpOptionsData.data
        ? getBillingIntervalsToShow({
            signUpOptionsData: signUpOptionsData.data,
            highlightSubscriptionPlanId,
            currentSubscriptionPlan,
            promoCodeEligiblePlanIds,
          })
        : undefined

  const optionsReady = signUpOptionsData.data && billingInterval
  const options = useMemo(() => {
    if (optionsReady) {
      const options = getSignUpOptions({
        billingInterval,
        promoCode: codeInputValue,
        setStripePreviewModalState,
        signUpOptionsData: {
          ...signUpOptionsData.data,
          recommendedSubscriptionPlans: promoCodeEligiblePlanIds?.length
            ? signUpOptionsData.data.recommendedSubscriptionPlans.filter(
                (plan) => promoCodeEligiblePlanIds.includes(plan.id),
              )
            : signUpOptionsData.data.recommendedSubscriptionPlans,
        },
        highlightSubscriptionPlanId,
        authenticated: Boolean(auth.user),
        subscription,
        groupMembership:
          groupMembershipPlanId && membershipCode
            ? { planId: groupMembershipPlanId, membershipCode }
            : undefined,
        isUpgrading: Boolean(
          offeringIds?.length && subscriptionWillRenew(subscription),
        ),
        contentId,
        recommId,
      })

      // Sort the user's current plan to the end on mobile to get it out of the
      // way when upgrading, changing plans, etc.
      if (layout === "narrow") {
        options.sort((a, b) =>
          a.current && !b.current ? 1 : !a.current && b.current ? -1 : 0,
        )
      }

      return options
    } else {
      return []
    }
  }, [
    layout,
    optionsReady,
    signUpOptionsData.data,
    billingInterval,
    codeInputValue,
    promoCodeEligiblePlanIds,
    highlightSubscriptionPlanId,
    auth,
    subscription,
    groupMembershipPlanId,
    membershipCode,
    offeringIds,
    contentId,
    recommId,
  ])

  /**
   * CODES
   *
   * Handle promo codes or group membership codes provided via the code input or
   * via route params.
   */

  // Track the current subscription plan in a ref so that it can be accessed in
  // the promo code input effect without re-running the effect when it changes
  const currentSubscriptionPlanRef = useRef(currentSubscriptionPlan)
  useEffect(() => {
    currentSubscriptionPlanRef.current = currentSubscriptionPlan
  }, [currentSubscriptionPlan])

  // Debounce requests to check if the input value is a valid promo code or group
  // membership code and set the input state based on the results
  const timeout = useRef<NodeJS.Timeout>()
  const codeInputValueRef = useRef("")
  useEffect(() => {
    if (
      codeInputValueRef.current !== codeInputValue.trim() &&
      // Do nothing if signUpOptionsData hasn't loaded yet since we need to know the
      // list of plans to validate the promo code for
      signUpOptionsData.data
    ) {
      // Reset promo code related state whenever the effect runs
      setGroupMembershipPlanId(undefined)
      setMembershipCode(undefined)
      setPromoCodeEligiblePlanIds(undefined)

      clearTimeout(timeout.current)
      const trimmedCode = codeInputValue.trim()

      // Track the input value in a ref so we have a simple way to tell if
      // promo code or group membership code validation requests are stale
      codeInputValueRef.current = trimmedCode

      if (trimmedCode) {
        setCodeInputState({ type: "loading" })
        timeout.current = setTimeout(() => {
          Promise.all([
            api
              .get<WebPaymentPromoCodeResponse>(
                `/integrations/web-payment/promo-codes/${strictEncodeUriComponent(trimmedCode)}?${queryString.stringify(
                  {
                    planId:
                      signUpOptionsData.data.recommendedSubscriptionPlans.map(
                        (plan) => plan.id,
                      ),
                  },
                )}`,
              )
              .then((res) => ({ status: "fulfilled" as const, value: res }))
              .catch((e) => ({ status: "rejected" as const, reason: e })),
            api
              .get<StripePromoCodeResponse>(
                `/integrations/stripe/promo-codes/${strictEncodeUriComponent(trimmedCode)}?${queryString.stringify(
                  {
                    planId:
                      signUpOptionsData.data.recommendedSubscriptionPlans.map(
                        (plan) => plan.id,
                      ),
                  },
                )}`,
              )
              .then((res) => ({ status: "fulfilled" as const, value: res }))
              .catch((e) => ({ status: "rejected" as const, reason: e })),
            checkGroupMembershipCode(trimmedCode)
              .then((res) => ({ status: "fulfilled" as const, value: res }))
              .catch((e) => ({ status: "rejected" as const, reason: e })),
          ])
            .then(
              ([
                webPaymentPromoCodeResult,
                stripePromoCodeResult,
                groupMembershipCodeResult,
              ]) => {
                const promoCodeResponse =
                  webPaymentPromoCodeResult.status === "fulfilled" &&
                  webPaymentPromoCodeResult.value.data.type !== "invalid"
                    ? webPaymentPromoCodeResult.value.data
                    : stripePromoCodeResult.status === "fulfilled"
                      ? stripePromoCodeResult.value.data
                      : undefined
                const groupMembershipCodeResponse =
                  groupMembershipCodeResult.status === "fulfilled"
                    ? groupMembershipCodeResult.value
                    : undefined

                if (
                  // Both network requests failed
                  (!promoCodeResponse && !groupMembershipCodeResponse) ||
                  // The promo code network request failed and the user input was
                  // not a valid group membership code
                  (!promoCodeResponse &&
                    groupMembershipCodeResponse?.status === "invalid") ||
                  // The user input was not a valid promo code and the group
                  // membership code network request failed
                  (promoCodeResponse?.type === "invalid" &&
                    !groupMembershipCodeResponse)
                ) {
                  // This will be caught by the catch call below
                  throw new Error(
                    stripePromoCodeResult.status === "rejected"
                      ? stripePromoCodeResult.reason
                      : groupMembershipCodeResult.status === "rejected"
                        ? groupMembershipCodeResult.reason
                        : undefined,
                  )
                }

                if (trimmedCode === codeInputValueRef.current) {
                  if (
                    promoCodeResponse?.type === "percent" ||
                    promoCodeResponse?.type === "amount"
                  ) {
                    setCodeInputState({
                      type: "success",
                      message: getPromoCodeDiscountMessage(promoCodeResponse),
                    })
                    setPromoCodeEligiblePlanIds(
                      promoCodeResponse.eligibleSubscriptionPlanIds,
                    )
                    // Reset the billing interval in case filtering by
                    // eligibleSubscriptionPlanIds changes which interval
                    // can/should be shown
                    setUserSelectedBillingInterval(undefined)
                  } else if (groupMembershipCodeResponse?.status === "valid") {
                    setCodeInputState({
                      type: "success",
                    })
                    setGroupMembershipPlanId(
                      groupMembershipCodeResponse.subscriptionPlanId,
                    )
                    setMembershipCode(codeInputValue)
                  } else if (groupMembershipCodeResponse) {
                    setCodeInputState({
                      type: "error",
                      message: getInvalidGroupMembershipCodeMessage(
                        groupMembershipCodeResponse,
                      ),
                    })
                  } else {
                    setCodeInputState({
                      type: "error",
                      message: t("Invalid promo code."),
                    })
                  }
                }
              },
            )
            .catch((e) => {
              logError(
                new Error(
                  "[Promo code input] Failed to fetch promo or group membership code data",
                  { cause: e },
                ),
              )
              setCodeInputState({
                type: "error",
                message: t("An error occurred. Please try again."),
              })
            })
            .finally(() => {
              setLoadingRouteSignUpCode(false)
            })
        }, SIGN_UP_CODE_INPUT_DEBOUNCE_MS)
      } else {
        setCodeInputState({ type: "idle" })
      }
    }
  }, [codeInputValue, signUpOptionsData.data, t])

  /**
   * PASSTHROUGH
   *
   * If the route contains a "passthrough" parameter then trigger the
   * highlighted option's checkout action without presenting our checkout page
   * at all. This is helpful when tenants want to create their own checkout
   * experience.
   */

  const [passthrough, setPassthrough] = useState(
    route.params[PASSTHROUGH_ROUTE_PARAM] !== undefined,
  )
  const passthroughOption = passthrough
    ? options.find((option) => option.highlighted)
    : undefined

  useEffect(() => {
    if (!passthrough) {
      return
    }

    // If passthrough is set and we found a sign up option to pass through to
    // then go for it.
    if (passthroughOption?.button?.onPress) {
      unsetQueryParam(PASSTHROUGH_ROUTE_PARAM)
      passthroughOption.button.onPress()
    }
    // If we couldn't find a good option to pass through to then revert to the
    // normal checkout flow.
    else if (optionsReady) {
      unsetQueryParam(PASSTHROUGH_ROUTE_PARAM)
      setPassthrough(false)
    }
  }, [passthrough, optionsReady, passthroughOption])

  /**
   * RENDER
   */

  const asyncViewProps = useAsyncViewPropsForQueries(
    [userSubscriptions, signUpOptionsData],
    {
      forceLoading:
        !callToSubscribe || loadingRouteSignUpCode || !billingInterval,
    },
  )

  // Break the cards up into rows for the wide layout
  const cardRows: number[][] = []
  if (layout === "wide") {
    const cardCount = options.length
    const cardsPerRow = getCardsPerRow({ displayWidth, cardCount })
    for (let cardIndex = 0; cardIndex < cardCount; cardIndex++) {
      const rowIndex = Math.floor(cardIndex / cardsPerRow)
      cardRows[rowIndex] ||= []
      cardRows[rowIndex].push(cardIndex)
    }
  }

  // Hold the splash screen open in passthrough mode to avoid awkwardly flashing
  // the app UI before we head on to the account creation or payment flow.
  return passthrough ? (
    <SplashScreen />
  ) : (
    <>
      <Layout layout={layout} {...asyncViewProps}>
        <PaddingContainer layout={layout} gap="xlarge">
          <Column gap="large">
            <CallToSubscribe
              layout={layout}
              onReady={setCallToSubscribeReady}
            />
            {billingInterval && billingInterval !== "all" ? (
              <BillingIntervalToggle
                value={billingInterval}
                onChange={setUserSelectedBillingInterval}
              />
            ) : null}
            {includeCodeInput ? (
              <PromoCodeInput
                layout={layout}
                state={codeInputState}
                value={codeInputValue}
                onChange={setCodeInputValue}
              />
            ) : null}
          </Column>
          {layout === "wide" ? (
            cardRows.map((row, rowIndex) => (
              <CardRow key={rowIndex}>
                {row.map((cardIndex) => {
                  const option = options[cardIndex]
                  return (
                    <SignUpOptionCard
                      key={getCardKey(option)}
                      layout={layout}
                      option={option}
                    />
                  )
                })}
              </CardRow>
            ))
          ) : (
            <CardColumn>
              {options.map((option) => (
                <SignUpOptionCard
                  key={getCardKey(option)}
                  layout={layout}
                  option={option}
                />
              ))}
            </CardColumn>
          )}
          <Column gap="medium">
            {currentSubscriptionPlan ? (
              <Text textStyle="body" color="secondary">
                {t("Current plan: {{planName}}", {
                  planName: [
                    currentSubscriptionPlan.name,
                    currentSubscriptionPlan.provider !== "groupMembership"
                      ? formatMoney(
                          currentSubscriptionPlan.price,
                          i18n.language,
                        ) + formatPlanIntervalShort(currentSubscriptionPlan)
                      : undefined,
                  ]
                    .filter(Boolean)
                    .join(" "),
                  interpolation: { escapeValue: false },
                })}
              </Text>
            ) : !auth.user ? (
              <Text textStyle="body" alignment="center" color="secondary">
                {t("Already have an account?")}{" "}
                <Text
                  textStyle="body"
                  color="accent"
                  onPress={() => authenticator.login()}
                  role="link"
                  aria-label={t("Sign In")}
                >
                  {t("Sign In")}
                </Text>
              </Text>
            ) : null}
            <RestorePurchasesLink />
          </Column>
        </PaddingContainer>
      </Layout>
      {includeStripeProrationPreviewModal ? (
        <StripeProrationPreviewModal
          preview={
            stripePreviewModalState.open
              ? stripePreviewModalState.preview
              : undefined
          }
          open={stripePreviewModalState.open}
          onClose={() => setStripePreviewModalState({ open: false })}
          action={
            stripePreviewModalState.open
              ? stripePreviewModalState.action
              : undefined
          }
        />
      ) : null}
    </>
  )
}
